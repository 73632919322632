.App {
  font-family: sans-serif;
}

/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-primary-1);
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
=============== 
Stripe Styles
===============
*/
.nav {
  /* height: 5rem; */
  display: flex;
  align-items: right;
  justify-content: right;
  background: transparent;
  position: relative;
  z-index: 1;
  padding-top: 35px !important;
}

.nav-center {
  width: 90vw;
  max-width: var(--max-width);
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  font-size: 1rem;
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;
  color: white;
  background: var(--clr-black);
  cursor: pointer;
  transition: var(--transition);
}

.btn:hover {
  background: var(--clr-grey-5);
}

.nav-links {
  display: none;
}

.signin-btn {
  display: none;
}

.hero::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 65%;
  top: 0;
  left: 0;
  background: url(./images/hero.svg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.hero {
  position: relative;
  min-height: 100vh;
  margin-top: -5rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  /* background-image: linear-gradient(to bottom right, #a35def, #92d7fd, #fff); */
  background-image: linear-gradient(to bottom right, #b1b2c4, #92d7fd, #a35def);
  /* background-image:linear-gradient(to bottom right,#A890FE, #EA8D8D); */
}

.hero-center {
  width: 90vw;
  max-width: var(--max-width);
  display: grid;
  align-items: center;
}

.hero-info h1 {
  text-transform: none;
  max-width: 500px;
  margin-bottom: 2rem;
}

.hero-info p {
  max-width: 35em;
  line-height: 1.8;
}

.hero-images {
  display: none;
}

/* nav media query */
@media screen and (min-width: 800px) {
  .nav-center {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }

  .toggle-btn {
    display: none;
  }

  .signin-btn {
    display: inline-block;
  }

  .nav-links {
    display: block;
    justify-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    height: 100%;
    display: grid;
    align-items: center;
  }

  .nav-links li {
    height: 100%;
  }

  .link-btn {
    height: 100%;
    background: transparent;
    border-color: transparent;
    font-size: 1.1rem;
    color: black;
    text-transform: capitalize;
    letter-spacing: 1px;
    width: 10rem;
    font-weight: 500;
  }
}

/* hero media query */
@media screen and (min-width: 800px) {
  .hero::before {
    background-size: contain;
    height: 100%;
  }

  .hero-center {
    grid-template-columns: 2fr 1fr;
  }

  .hero-info h1 {
    font-size: 3rem;
  }

  .hero-info p {
    font-size: 1.25rem;
  }

  .hero-images {
    display: block;
    justify-self: center;
  }

  /* .hero-images img{
    width: 370px;
  } */
  .phone-img {
    width: 12rem;
  }
}

@media screen and (min-width: 1200px) {
  .hero-center {
    grid-template-columns: 2fr 1fr;
    align-items: end;
    padding-bottom: 12vh;
  }

  .hero-info h1 {
    max-width: 100%;
    font-size: 4rem;
  }

  .hero-images {
    align-self: end;
  }

  .phone-img {
    width: 15rem;
  }
}

@media screen and (min-width: 1400px) {
  .hero-center {
    padding-bottom: 12vh;
  }

  .phone-img {
    width: 17rem;
  }
}

/* sidebar */
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: var(--transition);
  transform: scale(0);
  background: rgba(0, 0, 0, 0.5);
}

.sidebar-wrapper.show {
  visibility: visible;
  z-index: 2;
  transform: scale(1);
}

.sidebar {
  width: 90vw;
  height: 95vh;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 4rem 2rem;
}

.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-grey-5);
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.sidebar article {
  margin-bottom: 2rem;
}

.sidebar-sublinks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.25rem;
}

.sidebar-sublinks a {
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.sidebar-sublinks svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}

/* .toggle-btn {
  display: block;
} */

@media screen and (min-width: 800px) {
  .sidebar-wrapper {
    display: none;
  }

  .toggle-btn {
    display: none;
  }
}

/* links */
.submenu {
  background: var(--clr-white);
  box-shadow: var(--dark-shadow);
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: none;
  padding: 2rem;
  border-radius: var(--radius);
  transition: var(--transition);
}

.submenu::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--clr-white);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu.show {
  display: block;
}

.submenu-center {
  display: grid;
  gap: 0.25rem 2rem;
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.submenu h4 {
  margin-bottom: 1.5rem;
}

.submenu-center a {
  width: 10rem;
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.submenu-center svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}

body {
  background-color: #e2cfcf !important;
}

.sub-heading {
  padding: 60px 0 30px 0;
  text-transform: uppercase;
  font-family: fangsong;
  font-weight: 600;
  word-spacing: 5px;
  letter-spacing: 2px;
  font-size: 30px;
}

.btn {
  color: #f9fafb !important;
  background: #a35fef !important;
  font-weight: 600 !important;
}

.layout-img {
  border: 20px solid #a35fef;
  padding: 20px;
  width: 90%;
}

section {
  padding: 80px 0;
}

.content-block {
  background-color: #a35fef52;
  padding: 70px !important;
}

.content-heading {
  font-weight: 700;
  font-size: 2.5rem;
  margin: 0;
  text-transform: uppercase;
  font-family: fangsong;
}

.content-text {
  font-size: 1.25rem;
  font-weight: 400;
  padding: 40px 0px;
}

.logo {
  width: 183px;
  padding: 15px 0px;
  position: absolute;
  z-index: 10;
  left: 60px;
  float: left !important;
}

.banner-healing {
  /* background-image: url('./images/banner-page-7.png'); */
  background-size: 100% 100%;
  background-color: black;
}

.banner-healing p {
  color: #ab7c7c;
  font-family: fangsong;
  /* width: 600px; */
  padding: 40px 0;
  font-size: 22px !important;
}

.banner-healing h1 {
  color: #ab7c7c;
  font-family: fangsong !important;
  font-size: 3.6rem;
  width: auto;
  text-transform: uppercase;
}

.heading-content {
  padding: 60px 80px;
  padding-left: 80px !important;
}

.image-right {
  text-align: right;
}

.banner-vastu {
  background-image: url('./images/banner-page-7.png');
  background-size: 100% 100%;
}

p {
  font-size: 20px;
}

.sc-gzVnrw {
  padding: 18px !important;
}

.sc-gzVnrw.hQLxyS {
  color: black;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

/* 
.color-white, .color-white p {
  color: white !important;
} */

.banner-healing,
.banner-vastu {
  padding: 0 !important;
  height: 300px;
  margin-top: 20px;
}


/* .image-clip{ */
/* clip-path: polygon(28% 0, 76% 0, 100% 100%, 0% 100%); */
/* clip-path: polygon(10% 0, 90% 1%, 100% 100%, 0% 100%);
} */

.image-block {
  padding: 30px;
}

.heading {
  padding: 40px 0 0 0;
}

body {
  background-color: #444442;
}

h1 {
  font-family: 'Poppins', sans-serif, 'arial';
  font-weight: 600;
  font-size: 72px;
}

h4 {
  font-family: 'Roboto', sans-serif, 'arial';
  font-weight: 400;
  font-size: 20px;
  color: #9b9b9b;
  line-height: 1.5;
}

/* ///// inputs /////*/

input:focus~label,
textarea:focus~label,
input:valid~label,
textarea:valid~label {
  font-size: 0.75em;
  color: #999;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  float: left;
  width: 293px;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

.styled-input label {
  color: #999;
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 650px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: #2d2d2d;
  color: white;
  border-radius: 4px;
}

input:focus,
textarea:focus {
  outline: 0;
}

input:focus~span,
textarea:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 15em;
}

.input-container {
  width: 650px;
  max-width: 100%;
  margin: 20px auto 25px auto;
}

.submit-btn {
  float: right;
  padding: 7px 35px;
  border-radius: 60px;
  display: inline-block;
  background-color: #4b8cfb;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
    0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.10),
    0 1px 1px 0 rgba(0, 0, 0, 0.09);
}

@media (max-width: 768px) {
  .submit-btn {
    width: 100%;
    float: none;
    text-align: center;
  }
}

input[type=checkbox]+label {
  color: #ccc;
  font-style: italic;
}

input[type=checkbox]:checked+label {
  color: #f00;
  font-style: normal;
}

.banner-img {
  height: 400px;
  width: 145px;
}

.image-block {
  padding: 25px;
}

.vastu img {
  width: 100%;
}

img {
  width: auto;
}

.nav {
  flex-wrap: nowrap !important;
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-times:before {
  content: "\f0c9";
}

.hero-info p {
  font-size: 1.4rem;
  font-weight: 500;
}


.hero-info h1 {
  max-width: 100%;
  font-size: 3.5rem;
  text-transform: uppercase;
  font-family: fangsong;
  font-weight: 600;
  color: #291882;
}

.MuiTypography-h5 {
  font-size: 20px !important;
  font-family: sans-serif !important;
  font-weight: 600 !important;
  line-height: 1.334;
  letter-spacing: 0em;
  padding: 4px 0px 15px 0px;
  text-transform: uppercase;
}

li.nav-item-n.nav-item,
a.nav-item-n.caret.nav-link,
.cta-text h4,
.footer-widget-heading h3 {
  text-transform: uppercase;
}

.cta-text h4,
.footer-widget-heading h3 {
  word-spacing: 0px;
  letter-spacing: 1px;
  color: black !important;
  font-size: 18px !important;
}

.footer-text {
  font-weight: 500;
  padding-right: 40px;
}

.styles-module_floatingWhatsapp__1akvz {
  height: 0 !important;
}

.styles-module_chatFooter__1yhZR form .styles-module_input__2IYfc {
  color: black;
}

.image-fit {
  width: 100%;
}

h3 {
  padding: 15px 0;
}

.MuiCardContent-root {
  height: 250px;
}

p {
  text-align: justify;
}

.image-mobile {
  display: none;
}

iframe {
  height: 600px;
  width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .styled-input {
    width: 100%;
  }

  .banner-healing h1 {
    width: auto;
  }

  .image-right {
    display: none;
  }

  .banner-healing p {
    width: auto;
  }

  .heading-content {
    padding: 25px !important;
  }

  .banner-healing p {
    padding: 0;
    padding-top: 20px;
  }

  .banner-healing {
    margin-top: 30px;
  }

  section {
    padding: 35px 0;
  }

  .logo {
    left: 15px;
  }

  .nav-item-n {
    display: block;
    padding: 0 0px;
  }

  .navi-menu .container {
    max-width: 95%;
    padding: 0;
  }

  img {
    width: auto;
    max-height: 300px;
  }

  .banner-healing,
  .banner-vastu {
    height: auto;
  }

  .image-block {
    padding: 5px;
  }

  .banner-healing h1 {
    font-size: 2.6rem;
  }

  .row {
    margin: 0 !important;
  }

  .hero-info h1 {
    font-size: 3rem;
  }
  .nav-item-n {
    padding: 0;
    font-size: 35px !important;
  }
  .image-mobile {
    display: block;

  }
  .image-mobile img{
    width: 100%;
  }
  img {
      width: 100%;
  }

  iframe {
    height: 300px;
  }

  .padding-zero {
    padding: 0 !important;
  }

  .image-clip{
    width: auto;
  }
}